.game-wrapper {
    width: 100%;
    /* background-image: url('../../assets/images/snakebg.png'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 16px;
    text-align: center;
}

.score-box {
    padding: 20px;
    position: relative;
    display: inline-block;
}
.score-box span {
    color: #750000;
    font-size: 32px;
}
.game-container {
    width: 400px;
    height: 400px;
    position: relative;
    margin:0 auto;
}

.boundary {
    width: 400px;
    height: 400px;
    overflow: hidden;
    box-sizing: border-box;
    outline: 10px solid #575d42;
    outline-offset: 4px;
    border-radius: 5px;
    background-color: #e8efd4;
}



.food,
.snake {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: rgb(214, 214, 214);
}

.snake {
    position: absolute;
    transform: scale(1.2);
    background-color: rgb(3, 87, 3);
}

.food {
    position: absolute;
    background-color: red;
    border-radius: 50%;
    animation: food .8s infinite;
    z-index: 99;
}

@keyframes food {
    0% {
        transform: scale(1);
        background-color: rgb(173, 0, 0);
    }

    100% {
        transform: scale(2);
        background-color: rgb(255, 94, 94);
    }
}
.button-section{
    padding: 20px;
   display: flex;
   gap: 10px;
   justify-content: center;
}



.start-btn {
    font-weight: 600;
    background-color: rgb(2, 53, 2);
    color: white;
    padding: 16px 48px;
}

.start-btn:hover,
.btn:first-child:active {
    background-color: rgb(6, 105, 6);
    color: white;
}

.game-over {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.699);
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-overBox {
    padding: 50px;
    border-radius: 16px;
    background-color: black;
}

.game-overBox {
    font-family: "VT323";
    font-size: 2rem;
    text-align: center;
    animation-name: example;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes example {
    0% {
        color: lime;
        text-shadow: 0 0 20px green;
    }

    25% {
        color: green;
        text-shadow: 2px 2px 2px lime;
        transform: translate(-2px, 1px);
    }

    40% {
        color: lime;
        text-shadow: none;
        transform: translate(0, 0);
    }

    50% {
        color: green;
        text-shadow: 5px 5px 2px blue, -5px -5px 2px red;
        transform: translate(0px, 5px);
    }

    70% {
        color: lime;
        text-shadow: none;
        transform: translate(0, 0);
    }

    80% {
        color: lime;
        text-shadow: 0 0 20px green;
        transform: translate(-2px, 1px);
    }

    100% {
        color: lime;
        text-shadow: none;
    }
}