.bg{
    background-color: rgb(35, 92, 129);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    gap:20px;
  }
  .container{
    width: 600px;
   /* height: 600px; */
    background-color: rgb(45, 121, 171);
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* align-items: center; */
    padding:20px;
    transition: all .5s ease-in-out;
  }
  .container>div{
    width: 25%;
  }
  .card-wrapper{
    /* width:25%; */
    aspect-ratio: 1/1;
    padding:10px;
    transition: all .5s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
  }
  .card{
    width: 100%;
    height: 100%;
    background-color: rgb(226, 255, 226);
    font-size: 5rem;
    text-align: center;
    line-height:1.7;
    transform: rotateY(180deg);
    backface-visibility: hidden; 
  position: relative;
  cursor: pointer;
  }
  .card-wrapper .card.active{
    transform: rotateY(0deg);
    transition: all .5s ease-in-out;
  }
  
  .card-wrapper .card.active::after{
    transform: rotateY(180deg);
    backface-visibility: hidden;
    transition: all .5s ease-in-out;
  
  }
  .card::after{
    content: '';
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    background-color: green;
    position: absolute;
    transform: rotateY(0deg);
    backface-visibility: visible;
    transition: all .5s ease-in-out;
    cursor: pointer;
  }
  button{
    padding: 10px 24px;
    cursor: pointer;
  }
  .winner-wrapper{
    position: absolute;
    left:0;
    top:0;
    z-index: 99;
    width: 100%;
    height: 100vh;
    background-color: #1a17179a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .winner-pop{
    background-color: rgb(90, 240, 90);
    padding:50px;
    border-radius: 5px;
  }
  .winner-pop h1{
    color:rgb(4, 60, 4);
    text-align: center;
    padding:10px;
  }
  .winner-pop h1:nth-of-type(1){
    font-size: 90px;
  }
  @media(max-width : 768px){
    .container>div{
      width: 50%;
    }
    .bg{
      min-height: 100vh;
      height: unset;
      padding: 16px;
    }
  }