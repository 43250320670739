@font-face {
    font-family: 'Karla';
    src: url(./assets/static/Karla-Regular.ttf);
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Karla';
}

.mycontainer{
    max-width: 1200px;
    margin:0 auto;
}
